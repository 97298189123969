import * as React from 'react'
import { Link } from 'gatsby'

import Seo from '../components/seo'

const NotFoundPage = () => (
  <div className='not-found'>
    <Seo title='404: Not found' />
    <h1>404: Not Found</h1>
    <Link to='/'>Go back to home</Link>
  </div>
)

export default NotFoundPage
